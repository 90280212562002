<template>
  <div class="btn-wrapper">
    <button
      class="btn"
      :class="{pressed:touchDown}"
      @touchstart="TouchStart"
      @touchend="TouchEnd"
      oncontextmenu="return false;"
      :aria-label="button.label"
    >
      <div>
        <span
          v-if="button.label != '' && !button.icon"
          class="btn-label"
          :style="`--maxFontSize:${adaptiveFontSize}`"
          >
          {{ button.label }}
        </span>
        <d-icon v-if="button.icon" :icon="button.icon"/>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    block: {
      type: Object,
      required: true,
    },
    button: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      touchDown: false,
    };
  },
  computed: {
    label() {
        return this.button.label ? this.button.label.trim() : ''
    },
    adaptiveFontSize() {
      const length = this.label.length;
      if (length === 1) return '70cqw';
      if (length === 2) return '60cqw';
      if (length === 3) return '50cqw';
      return '40cqw';
    }
  },
  methods: {
    TouchStart() {
      this.touchDown = true;
      this.EmitButtonState(true);
    },
    TouchEnd(e) {
      e.stopPropagation();
      this.touchDown = false;
      this.EmitButtonState(false);
    },
    EmitButtonState(clickState) {
      this.$socket.client.emit("gameMessage", {
        type: "controllerData",
        controllerType: "button",
        name: this.button.socketName,
        input: {
          pressed: clickState,
        },
      });
    },
  },
  mounted() {
    //
  },
};
</script>

 <style scoped lang="scss">
 @import "@/styles/variables.scss";
.btn-wrapper {
  position: relative;
  z-index: 1;
  padding: 5%;
  height: 100%;
  aspect-ratio: 1;
  flex: 1;
  border-radius: 50%;
  background: var(--userColor, $primary);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
}

.btn {
  position: relative;
  display: block;
  text-align: center;
  background: var(--userColor, silver);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.1s ease;
  container-type: size;

  & > div {
    position: relative;
    z-index: 2;  // Aumentado para estar por encima del pseudo-elemento
    color: white;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.4);

    span.btn-label {
      line-height: .7;
      font-size: min(var(--maxFontSize,70cqw), 5rem);
    }

    .icon {
      width: 50%;
      height: 50%;
      filter: drop-shadow(0px 0 1px rgba(0, 0, 0, 0.4))
    }
  }

  &.pressed {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    color: #ffeded;
    .btn-label {
      zoom: .97;
    }
    .icon {
      width: 47%;
      height: 47%;
    }
  }
}
</style>